$theme-color: #868686;

.image-editor {
	color: $theme-color;
	border: solid 1px #2b2a30;
	max-height: 100%;
	&__preview {
		height: 45px;
		width: 45px;
		background: black;
		border: solid 1px #2b2a30;
		position: absolute;
		left: 20px;
		top: 20px;
		border-radius: 50%;
	}
	&__cropper {
		background: #0f0e13;
		height: 400px;
		max-height: 100%;
		position: relative;
	}
	&__slider {
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		bottom: 20px;
		position: absolute;
	}
	&__cropper-overlay {
		transition: 0.5s;
		&--faded {
			color: rgba(black, 0.9);
		}
	}
	&__service {
		pointer-events: none;
		left: -100%;
		position: absolute;
	}
	&__reset-button {
		position: absolute;
		right: 20px;
		top: 20px;
		background: rgba(white, 0.1);
		&:hover {
			fill: $theme-color;
			background: rgba(white, 0.2);
		}
		&--hidden {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.image-editor-slider {
	width: 100%;
	height: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border-radius: 10px;
	cursor: pointer;
	max-width: 380px;
	background: rgba(#1B1A21, 0.4);
	padding-left: 16px;
	padding-right: 16px;

	&__wrapper {
		display: flex;
		align-items: center;
		max-width: 400px;
		width: 100%;
	}

	&__line {
		background: rgba(white, 0.5);
		height: 2px;
		width: 100%;
		display: flex;
		position: relative;
		align-items: center;
	}

	&__fill {
		background: white;
		align-self: stretch;
		flex-basis: auto;
		flex-direction: column;
		flex-shrink: 0;
		position: absolute;
		height: 2px;
	}

	&__dot {
		height: 8px;
		width: 8px;
		border-radius: 50%;
		background: white;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&__value {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		transition-duration: 0.5s;
		transition-property: font-size, opacity;
		color: white;
		font-size: 10px;
		font-weight: 500;
		top: -20px;
		vertical-align: bottom;
		transform: translate(-50%);
	}

	&__handler {
		width: 2px;
		height: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		transition-duration: 0.2s;
		transition-property: height;
		background-color: white;

		&:hover {
			height: 10px;
		}
		&--focus {
			height: 10px;
		}
		&--hidden {
			height: 4px;
		}
	}
}

.image-editor-navigation {
	background: #1b1a21;
	height: 84px;
	border-top: solid 1px #2b2a30;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 16px;
	padding-right: 16px;
	&__button {
		margin-left: 8px;
		margin-right: 8px;
        &__ok {
            color: #00e300 !important;
        }
	}
	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__upload-input {
		display: none;
	}

	@media (max-width: 540px) {
		padding-left: 8px;
		padding-right: 8px;
		&__button {
			margin-left: 4px;
			margin-right: 4px;
		}
	}
}

.image-editor-button {
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background: transparent;
	fill: #88888b;
	transition: 0.5s;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	cursor: pointer;
	color: currentColor;
	padding: 0;
	&--active {
		background: rgba(white, 0.03);
		fill: currentColor;
	}
	&:hover, &:focus {
		background: rgba(white, 0.03);
	}
	@media (max-width: 540px) {
		width: 32px;
		height: 32px;
	}
}

.adjustable-image-source {
	display: none;
}
